

<template>
  <div class="card-history-filters-search" @click="!loading_service ? setFilters() : ''">
    <b-skeleton class="skeleton-loading-service" v-if="loading_service"></b-skeleton>
    <div v-if="!show_input" class="w-100 d-flex justify-content-between align-items-start">
      {{ i.title ? i.title : getMainTitle(i) }}
      <b-dropdown class="avenir-medium dropdown-options-history-search" variant="link" no-caret right @click.stop>
        <template #button-content>
          <feather-icon icon="MoreHorizontalIcon"></feather-icon>
        </template> 
        <b-dropdown-item @click.stop="editTitleAction(i)"><feather-icon icon="EditIcon"></feather-icon> {{ $t('filters.editName') }}</b-dropdown-item>
        <b-dropdown-item @click.stop="deleteHistory(i.uuid)"><feather-icon icon="TrashIcon"></feather-icon> {{ $t('lists.delete') }}</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="mb-1" v-else>
      <b-form-input v-model="title" class="mb-1" maxlength="250" autocomplete="off" @click.stop></b-form-input>
      <b-button @click.stop="changeTitle(i.uuid)" class="blue-button" variant="blue-button">Renombrar</b-button>
    </div>
    <div class="w-100 d-flex justify-content-between">
      <div class="avenir-medium">
        <span v-if="i.request.total_results">{{getFormat(i.request.total_results)}}  {{ $t('search.results').toLowerCase() }}</span>
      </div>
      <div class="avenir-medium">{{ getDatev2(new Date(i.created_at)) }}</div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSkeleton,
} from 'bootstrap-vue'
import services_search from '@/services/search';
import { getFormat, nameNetworks } from '@/libs/utils/formats';
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: 'cardHistorySearch',
  components: {
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSkeleton,
  },
  data () {
    return {
      getFormat,
      getDatev2,
      history: [],
      all_results: [],
      is_editing_title: false,
      title: '',
      toggle_results: false,
      per_page: 16,
      current_page: 1,
      show_input: false,
    }
  },
  props: {
    i: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading_service: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setFilters() {
      this.$emit('set_filters', this.i)
    },
    deleteHistory(search_uuid) {
      services_search.deleteHistorySearch(search_uuid).then((response) => {
        if (response.status <= 400) {
          this.$emit('delete_history', search_uuid)
        }
      })
    },
    editTitleAction(filters) {
      this.title = filters.title ? filters.title : filters.network;
      this.show_input = true;
    },
    getMainTitle(filters) {
      let label = ''
      label += `${nameNetworks(filters.network, false)} ${filters.request.filters_label_used ? `• ${filters.request.filters_label_used}` : ''}`

      return label
    },
    changeTitle(searcher_uuid) {
      const title = this.title;
      services_search.changeTitleHistory(searcher_uuid, {title}).then((response) => {
        this.show_input = false;
        this.$emit('update_history', searcher_uuid, response)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.card-history-filters-search {
  background-color: white;
  border: 1px solid #e2e8f0;
  padding: 20px 24px;
  border-radius: 6px;
  min-height: 155px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.skeleton-loading-service {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #8a89891a;
}
</style>
<style lang="scss">
.dropdown-options-history-search button {
  padding: 0 !important;
}
</style>